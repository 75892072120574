<template>
  <div
    @click="$emit('click')"
    :class="[
      active ? 'text-white bg-primary dark:bg-contrast':'text-primary dark:text-secondary',
      {'animate-pulse rounded-md border-yellow-300':pulse === title}
    ]"
    class="`
      flex flex-row justify-center sm:justify-start
      px-2 py-2 my-1 cursor-pointer w-full
      border-2 border-transparent
      nav-bar-component
      dark:text-white
    `"
  >
    <div
      class="md:pl-7"
    >
      <dashboard-icon v-if="title ==='Dashboard'"/>
      <project-icon v-if="title ==='Projects'"/>
      <rewards-icon v-if="title ==='Rewards'"/>
      <invite-icon v-if="title ==='Invite'"/>
      <calculator-icon v-if="title ==='Estimators'"/>
      <assistance-icon v-if="title ==='Assistance'"/>
      <to-do-icon v-if="title ==='To Do'"/>
      <admin-icon v-if="title ==='Admin'"/>
      <left-chevron v-if="title === 'Back'"/>
      <settings-icon v-if="title === 'Settings'" />
    </div>

    <p
      class="hidden pl-4 font-semibold text-left sm:inline"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
import DashboardIcon from 'vue-material-design-icons/Home.vue';
import ProjectIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
import RewardsIcon from 'vue-material-design-icons/Gift.vue';
import InviteIcon from 'vue-material-design-icons/AccountGroupOutline.vue';
import CalculatorIcon from 'vue-material-design-icons/Calculator.vue';
import AssistanceIcon from 'vue-material-design-icons/CellphoneInformation.vue';
import ToDoIcon from 'vue-material-design-icons/Heart.vue';
import AdminIcon from 'vue-material-design-icons/KeyChain.vue';
import LeftChevron from 'vue-material-design-icons/ChevronLeft.vue';
import SettingsIcon from 'vue-material-design-icons/Cog.vue';

export default {
  name: 'InteriorPageContentContainerNavBarComponent',
  components: {
    DashboardIcon,
    ProjectIcon,
    RewardsIcon,
    InviteIcon,
    CalculatorIcon,
    AssistanceIcon,
    ToDoIcon,
    AdminIcon,
    LeftChevron,
    SettingsIcon,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    pulse: {
      type: String,
      required: false,
      validation: (val) => ['Projects', 'Rewards', 'Invite', 'Assistance', 'To Do', 'Calculators', 'Admin', ''].contains(val),
    },
  },
};
</script>
