<template>
  <div
    @click="closeNotifications"
    class="relative flex flex-col w-full h-full"
  >
    <div
      class="`
        absolute top-0 left-0 right-0 z-50
        overflow-hidden transition-all duration-700 ease-in-out max-h-0
      `"
      :class="{ 'max-h-44': globalErrorMessage.length }"
    >
      <notification-banner
          @hide-notification="$store.commit('ERROR_UPDATE', {message: ''})"
          color="red"
          class="flex justify-center flex-grow rounded-none"
          :timeout="7000"
          :showNotification="globalErrorMessage.length > 0"
      >
        {{globalErrorMessage}}
      </notification-banner>
    </div>

    <div
      class="`flex flex-col justify-center items-center absolute w-full h-full bg-film z-40 modal`"
      v-if="modalShow"
    >
      <slot name="modal" />
    </div>

    <interior-page-content-container-notifications
      :showSiteNotifications="showSiteNotifications"
      :notifications="notifications"
      @notification-clicked="notificationClicked($event)"
    />

    <div class="flex flex-row w-full h-full overflow-hidden">
      <interior-page-content-container-nav-bar
        v-if="showLeftNav"
        @navigate="navigate"
        @back-click="backClick"
        :show-back-button="showBackButtonInNav"
        :nav-selection="navSelection"
        :backButtonText="backButtonText"
        :pulse="pulse"
      />

      <div class="flex flex-col flex-grow h-full">
        <interior-page-content-container-top-bar
          @back-click="backClick"
          @settings-click="settingsClick"
          @notifications-click="notificationsClick()"
          :show-back-button="showBackButtonTopLeft"
          :name="name"
          :image="image"
          :new-notifications-exist="newNotificationsExist"
        >
          <slot name="title"/>
        </interior-page-content-container-top-bar>

        <div class="relative flex-grow w-full h-full overflow-hidden">

          <interior-page-content-container-walkthrough-button
            @click="showWalkthrough"
            :showRightPanel="showRightPanel"
          />

          <interior-page-content-container-body
            @right-action-navigate="navigateFromRightPanel"
            @clear-message="$emit('update:notificationMessage', $event)"
            :notificationMessage.sync="notificationMessage"
            :notificationTime="notificationTime"
            :notificationType="notificationType"
            :show-right-panel="showRightPanel"
            :show-right-controls="showRightControls"
          >
            <template #notification>
              <slot name="notification"/>
            </template>

            <template #body>
              <div
                v-if="loading"
                class="flex flex-col items-center justify-center h-full"
              >
                <loading-icon/>
              </div>
              <slot v-else name="content" />
            </template>
            <template #right-action-buttons>
              <slot name="right-action-buttons" />
            </template>
          </interior-page-content-container-body>

        </div>
      </div>
    </div>

    <!-- Bottom Navigation Bar -->
    <div
        v-show="showBottomNav"
        class="`
          grid grid-cols-3 flex-shrink
          py-4 bg-backfill text-primary border-t-2 border-contrast
          phone:hidden
        `"
    >
        <div class="flex flex-row justify-center">
          <slot name="bottom-nav-left"/>
        </div>
        <div class="flex flex-row justify-center">
          <slot name="bottom-nav-center"/>
        </div>
        <div class="flex flex-row justify-center">
          <slot name="bottom-nav-right"/>
        </div>

    </div>

  </div>
</template>

<script>
import { USER_NOTIFICATION_READ, USER_GET, USER_WALKTHROUGH_DISPATCH } from '@/store/actions';
import { USER_NAME, USER_IMAGE, USER_NOTIFICATIONS } from '@/store/getters';
import LoadingIcon from '@/components/shared/general/LoadingIcon.vue';
import NotificationBanner from '@/components/shared/general/NotificationBanner.vue';
import InteriorPageContentContainerTopBar from './InteriorPageContentContainerTopBar.vue';
import InteriorPageContentContainerBody from './InteriorPageContentContainerBody.vue';
import InteriorPageContentContainerNavBar from './InteriorPageContentContainerNavBar.vue';
import InteriorPageContentContainerNotifications from './InteriorPageContentContainerNotifications.vue';
import InteriorPageContentContainerWalkthroughButton from './InteriorPageContentContainerWalkthroughButton.vue';

export default {
  name: 'InteriorPageContentContainer',
  components: {
    InteriorPageContentContainerTopBar,
    InteriorPageContentContainerNavBar,
    InteriorPageContentContainerBody,
    InteriorPageContentContainerNotifications,
    InteriorPageContentContainerWalkthroughButton,
    NotificationBanner,
    LoadingIcon,
  },
  props: {
    navSelection: {
      type: String,
      required: true,
      validation: (val) => ['dashboard', 'projects', 'rewards', 'invite', 'assistance', 'to do', 'calculators', 'admin'].contains(val),
    },
    showBackButtonTopLeft: {
      type: Boolean,
      default: false,
    },
    showBackButtonInNav: {
      type: Boolean,
      required: true,
    },
    backButtonText: {
      type: String,
      required: false,
      default: 'Back',
    },
    showBottomNav: {
      type: Boolean,
      required: true,
    },
    showLeftNav: {
      type: Boolean,
      required: false,
      default: true,
    },
    modalShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    showRightPanel: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    notificationMessage: {
      type: String,
      required: false,
      default: '',
    },
    notificationTime: {
      type: Number,
      required: false,
      default: 5000,
    },
    notificationType: {
      type: String,
      required: false,
      default: 'success',
    },
    pulse: {
      type: String,
      required: false,
      validation: (val) => ['Projects', 'Rewards', 'Invite', 'Assistance', 'To Do', 'Calculators', 'Admin', ''].contains(val),
    },
    showRightControls: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showSiteNotifications: false,
    };
  },
  computed: {
    name() {
      return this.$store.getters[USER_NAME].split(' ')[0];
    },
    image() {
      return this.$store.getters[USER_IMAGE];
    },
    notifications() {
      const notifications = this.$store.getters[USER_NOTIFICATIONS];
      return notifications.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
    },
    newNotificationsExist() {
      return this.notifications.filter((notification) => !notification.read).length > 0;
    },
    globalErrorMessage() {
      return this.$store.getters.ERROR_GET_MESSAGE;
    },
  },
  methods: {
    navigate(routeName) {
      if (this.$route.name !== routeName) {
        if (routeName === 'Assistance') {
          this.$router.push({
            name: 'Assistance',
            params: { tab: 'appointment' },
          });
        } else this.$router.push({ name: routeName });
      }
    },
    notificationClicked({ newRoute }) {
      if (newRoute && this.$route.name !== newRoute) {
        setTimeout(() => {
          this.$router.push(newRoute);
        }, 450);
      }
      this.closeNotifications();
    },
    backClick() {
      this.$emit('back-click');
    },
    settingsClick() {
      if (this.$route.name !== 'Settings') {
        this.$router.push({ name: 'Settings' });
      }
    },
    notificationsClick() {
      this.showSiteNotifications = !this.showSiteNotifications;
      const readNotifications = this.notifications.reduce((acc, notification) => (
        !notification.read ? [...acc, notification.notification_id] : acc
      ), []);
      readNotifications.forEach((notificationId) => {
        this.$store.dispatch(USER_NOTIFICATION_READ, notificationId);
      });
    },
    closeNotifications() {
      if (this.showSiteNotifications) {
        this.showSiteNotifications = false;
        this.$store.dispatch(USER_GET);
      }
    },
    navigateFromRightPanel(route) {
      this.$router.push(route);
    },
    showWalkthrough() {
      console.log('Show the new walkthrough');
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 0);
      if (this.$route.name !== 'Dashboard') this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>
