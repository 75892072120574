<template>
    <div class="w-7 h-7 animate-spin text-content">
        <loading />
    </div>
</template>

<script>
import Loading from 'vue-material-design-icons/Loading.vue';

export default {
  name: 'LoadingIcon',
  components: {
    Loading,
  },
};
</script>
