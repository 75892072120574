<template>
  <div class="`
    hidden phone:block
    w-16 sm:w-40 md:w-56 lg:w-64
    z-0 flex-grow-0 flex-shrink-0
  `"
  style="flex-shrink: 1 0 auto;"
  ></div>
</template>
<script>
export default {
  name: 'RightBumper',
};
</script>
