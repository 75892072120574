<template>
  <div
    class="absolute bottom-0 right-0 z-20 flex"
  >
    <div
      @click="$emit('click')"
      class="`
        flex flex-col items-center justify-center
        w-14 h-14 p-2 m-3 rounded-full cursor-pointer bg-primary text-backfill trigger-walkthrough
        border border-contrast
      `"
    >
      <p class="text-4xl font-extrabold">?</p>
    </div>
    <right-bumper v-if="showRightPanel"/>
  </div>
</template>

<script>
import RightBumper from '@/components/shared/general/RightBumper.vue';

export default {
  name: 'InteriorPageContentContainerWalkthroughButton',
  components: {
    RightBumper,
  },
  props: {
    showRightPanel: {
      type: Boolean,
      require: true,
    },
  },
};
</script>
