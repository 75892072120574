<template>
  <div
    @click="click"
    class="`
      flex flex-row justify-start items-center min-w-min cursor-pointer text-content
      border-b border-contrast w-full
    `"
    :class="[notification.read ? 'bg-backfill' : 'bg-background']"
  >
    <div class="flex justify-end flex-shrink-0 w-6">
      <p v-if="!notification.read" class="w-2 h-2 rounded-full bg-content"></p>
      <p v-else class="w-2 h-2"></p>
    </div>
    <div class="flex flex-col py-3 pl-3 text-left items-between">
      <div class="pr-1 text-sm font-medium text-content">{{notification.text}}</div>
      <p class="text-xs text-content">{{time}}</p>
    </div>

  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'InteriorPageContentContainerNotificationComponent',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    time() {
      return moment(this.notification.date_created).fromNow();
    },
  },
  methods: {
    click() {
      const text = this.notification.text.toLowerCase();
      const deleted = text.indexOf('delete') !== -1;
      const created = text.indexOf('create') !== -1;
      const acceptedInvitation = text.indexOf('invitation') !== -1;
      let newRoute = {};
      if (text.indexOf('appointment') !== -1 || text.indexOf('consultation') !== -1) {
        newRoute = {
          name: 'Assistance',
          params: { tab: 'appointment' },
        };
      } else if (created) {
        if (this.notification.item_id) {
          newRoute = {
            name: 'Item',
            params: {
              project_id: `${this.notification.project_id}`,
              room_id: `${this.notification.room_id}`,
              item_id: `${this.notification.item_id}`,
            },
          };
        } else if (this.notification.room_id) {
          newRoute = {
            name: 'Room',
            params: {
              room_id: `${this.notification.room_id}`,
              project_id: `${this.notification.project_id}`,
            },
          };
        } else if (this.notification.project_id) {
          newRoute = {
            name: 'Project',
            params: {
              project_id: `${this.notification.project_id}`,
              tab: 'Declutter',
            },
          };
        } else {
          newRoute = { name: 'Projects' };
        }
      } else if (deleted) {
        if (this.notification.item_id) {
          newRoute = {
            name: 'Room',
            params: {
              project_id: `${this.notification.project_id}`,
              room_id: `${this.notification.room_id}`,
            },
          };
        } else if (this.notification.room_id) {
          newRoute = {
            name: 'Project',
            params: {
              project_id: `${this.notification.project_id}`,
              tab: 'declutter',
            },
          };
        } else if (this.notification.project_id) {
          newRoute = {
            name: 'Projects',
          };
        }
      } else if (acceptedInvitation) {
        newRoute = {
          name: 'ProjectSettings',
          params: {
            project_id: this.notification.project_id,
          },
        };
      }

      let isSameRoute = newRoute.name === this.$route.name;
      Object.keys(newRoute.params || {}).forEach((key) => {
        if (isSameRoute) isSameRoute = newRoute.params[key] === this.$route.params[key];
      });
      if (isSameRoute) this.$emit('click', { newRoute: null, notification: this.notification });
      else this.$emit('click', { newRoute, notification: this.notification });
    },
  },
};
</script>
