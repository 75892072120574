<template>
  <div
    class="`
      grid justify-between grid-cols-5 md:grid-cols-3 py-1 overflow-hidden
      border-b phone:flex border-contrast bg-backfill
      flex-shrink-0
    `"
  >

    <!-- Left logo / back button -->
    <div class="flex flex-row items-center justify-start phone:hidden">
      <div class="flex flex-row items-center w-40 h-8 phone:justify-center">
        <left-chevron
          @click="$emit('back-click')"
          :class=" showBackButton ? 'inline phone:hidden' : 'hidden' "
          class="p-2 ml-5 rounded-full cursor-pointer text-content bg-background"
        />
      </div>
    </div>

    <!-- Page title -->
    <div class="`
      flex items-center justify-center
      text-xl text-center phone:text-xl text-primary
      pl-5 font-extrabold
      mr-10
      col-span-3 md:col-span-1
    `">
      <slot />
    </div>

    <!-- Notifications / user -->
    <div class="flex items-start justify-end text-secondary">
      <div class="flex flex-row items-center h-12">
        <div class="relative">
          <div
            v-if="newNotificationsExist"
            class="relative w-2 h-2 bg-red-500 rounded-full top-1 left-3"
          ></div>
          <bell
            @click="openNotifications"
            :size="20"
            class="cursor-pointer text-primary dark:text-secondary"
          />
        </div>

        <div
          @click="$emit('settings-click')"
          class="`
            flex flex-row items-center
            p-1 rounded-full cursor-pointer phone:bg-background phone:mx-3
            user-settings
          `"
        >
          <div>
            <img
              :src="image"
              alt="User Nav Bar Profile Picture"
              class="w-6 h-6 rounded-full phone:w-6 phone:h-6"
            />
          </div>
          <p class="hidden px-2 font-bold sm:inline text-primary dark:text-secondary">{{ name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftChevron from 'vue-material-design-icons/ChevronLeft.vue';
import Bell from 'vue-material-design-icons/Bell.vue';

export default {
  name: 'InteriorPageContentContainerTopBar',
  components: {
    LeftChevron,
    Bell,
  },
  props: {
    showBackButton: {
      type: Boolean,
      requried: true,
    },
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    newNotificationsExist: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    openNotifications(event) {
      event.stopPropagation();
      this.$emit('notifications-click');
    },
  },
};
</script>
