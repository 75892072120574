<template>
  <div
    @click="stopProp"
    class="`
      absolute right-0 bg-green-200 rounded-lg w-72 h-2/3 bg-backfill
      mr-2 shadow-lg top-16
      transition-all duration-500 ease-in-out max-h-0
      overflow-hidden z-30
    `"
    :class="{'max-h-960': showSiteNotifications}"
  >
    <div class="h-full overflow-scroll border-2 border-contrast bg-backfill">
      <interior-page-content-container-notification-component
        v-for="(notification, index) in notifications" :key="index"
        :notification="notification"
        @click="$emit('notification-clicked', $event)"
      />
    </div>
  </div>
</template>

<script>
import InteriorPageContentContainerNotificationComponent from './InteriorPageContentContainerNotificationComponent.vue';

export default {
  name: 'InteriorPageContentContainerNotifications',
  components: {
    InteriorPageContentContainerNotificationComponent,
  },
  props: {
    showSiteNotifications: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
  },
  methods: {
    stopProp(event) {
      event.stopPropagation();
    },
  },
};
</script>
