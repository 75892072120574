<template>
  <div class="`
    top-0 left-0 flex-col flex-shrink-0 hidden w-16
    h-full sm:w-40 md:w-56 lg:w-64 phone:flex
  `">
    <div :class="`
      flex flex-col items-center flex-shrink h-full
      py-3 text-secondary bg-backfill border-r border-contrast border-opacity-70
      z-10
    `">

      <!-- Left logo -->
      <div class="flex flex-row w-full mt-3 mb-10">
        <div class="ml-2 md:ml-9"></div>
        <div class="hidden w-10 phone:flex"></div>

        <img
          src="/img/logos/KIV-logo-transparent.png"
          @click="$emit('navigate', 'Dashboard')"
          alt="Kept In View Logo"
          class="hidden h-5 mr-0 cursor-pointer sm:h-10 phone:inline"
        >
      </div>

      <div class="w-full overflow-y-auto">
        <interior-page-content-container-nav-bar-component
          v-for="option in options" :key="option.title"
          @click="$emit('navigate', option.navDest)"
          :title="option.title"
          :active="option.navSelection === navSelection"
          :pulse="pulse"
        />

        <interior-page-content-container-nav-bar-component
          v-if="isAdmin"
          @click="$emit('navigate', 'AdminChecklists')"
          title="Admin"
          :active="'admin' === navSelection"

        />

        <interior-page-content-container-nav-bar-component
          v-if="showBackButton"
          @click="$emit('back-click')"
          title="Back"
          class="back-button"
          :active="false"
        />
      </div>

    </div>
  </div>
</template>

<script>

import {
  USER_IS_ADMIN,
} from '@/store/getters';

import InteriorPageContentContainerNavBarComponent from './InteriorPageContentContainerNavBarComponent.vue';

export default {
  components: {
    InteriorPageContentContainerNavBarComponent,
  },
  props: {
    navSelection: {
      type: String,
      required: true,
      validation: (val) => ['projects', 'rewards', 'invite', 'assistance', 'to do', 'calculators', 'admin', ''].contains(val),
    },
    showBackButton: {
      type: Boolean,
      required: true,
    },
    backButtonText: {
      type: String,
      required: true,
    },
    pulse: {
      type: String,
      required: false,
      validation: (val) => ['Projects', 'Rewards', 'Invite', 'Assistance', 'To Do', 'Calculators', 'Admin', ''].contains(val),
    },
  },
  data() {
    return {
      options: [
        { title: 'Dashboard', navSelection: 'dashboard', navDest: 'Dashboard' },
        { title: 'Projects', navSelection: 'projects', navDest: 'Projects' },
        { title: 'Rewards', navSelection: 'rewards', navDest: 'Rewards' },
        { title: 'Invite', navSelection: 'invite', navDest: 'Invite' },
        { title: 'Assistance', navSelection: 'assistance', navDest: 'Assistance' },
        { title: 'To Do', navSelection: 'toDo', navDest: 'ToDo' },
        { title: 'Estimators', navSelection: 'calculators', navDest: 'Calculators' },
      ],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters[USER_IS_ADMIN];
    },
  },
};
</script>
