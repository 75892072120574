<template>
    <div class="flex flex-row flex-grow h-full overflow-y-auto">
        <div
            class="`
              absolute top-0 flex-grow flex z-10 w-full
              overflow-hidden transition-all duration-700 ease-in-out max-h-0
            `"
            :class="[
              { 'max-h-44': showNotification },
              showRightPanel ? 'justify-center phone:justify-between' : 'justify-center'
            ]"
        >
            <notification-banner
                @hide-notification="showNotification = false"
                :color="notificationType == 'success' ? 'green' : 'red'"
                class="flex justify-center flex-grow"
                :timeout="notificationTime"
                :showNotification="showNotification"
            >
              {{notificationMessage}}
            </notification-banner>
            <right-bumper v-if="showRightPanel"/>
        </div>

        <div
          class="flex flex-row flex-grow h-full overflow-y-auto"
        >
            <div class="flex flex-col flex-grow">
                <slot name="body"/>
            </div>
            <div
              :class="{'phone:flex' : showRightPanel}"
              class="`
                flex-col items-center flex-shrink-0 hidden
                w-16 sm:w-40 md:w-56 lg:w-64 h-full
              `"
            >
              <div
                class="`
                  flex flex-col absolute top-0 right-0 z-10
                  w-16 h-full sm:w-40 md:w-56 lg:w-64 bg-backfill
              `">
                <div v-if="showRightControls" class="flex flex-col h-52">
                  <slot name="right-action-buttons" />
                </div>
                <div class="flex-grow overflow-hidden">
                  <interior-page-content-container-sponsored/>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

import NotificationBanner from '@/components/shared/general/NotificationBanner.vue';
import RightBumper from '@/components/shared/general/RightBumper.vue';
import InteriorPageContentContainerSponsored from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainerSponsored.vue';

export default {
  name: 'InteriorPageContentContainerBody',
  components: {
    NotificationBanner,
    RightBumper,
    InteriorPageContentContainerSponsored,
  },
  props: {
    notificationType: {
      type: String,
      required: true,
      validator: (val) => ['alert', 'success'].includes(val),
    },
    notificationMessage: {
      type: String,
      required: true,
    },
    notificationTime: {
      type: Number,
      required: true,
    },
    showRightPanel: {
      type: Boolean,
      required: true,
    },
    showRightControls: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showNotification: false,
    };
  },
  watch: {
    notificationMessage(val) {
      if (val.length > 0) {
        this.showNotification = true;
      }
    },
    showNotification(val) {
      if (val === false) {
        setTimeout(() => {
          console.log('clearing message...');
          this.$emit('clear-message', '');
        }, 1000);
      }
    },
  },
};
</script>
