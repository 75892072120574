<template>
  <a
    :href="link"
    target="_blank"
    class="flex flex-row px-2 py-2 cursor-pointer sm:h-28 hover:bg-contrast"
  >
    <img
      :src="image"
      class="object-scale-down bg-white rounded-lg sm:w-1/2"
    />

    <div class="flex-col justify-center hidden ml-2 text-left sm:flex text-content">
      <p class="font-bold">
        {{title}}
      </p>
      <p class="text-sm opacity-60">
        {{domain}}
      </p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'InteriorPageContentContainerSponsoredComponent',
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
