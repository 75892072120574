<template>
  <div class="flex flex-col h-full">
    <p class="hidden my-4 ml-4 font-extrabold text-left opacity-80 text-content sm:flex">
      Sponsored
    </p>
    <div class="flex flex-col flex-grow">
      <interior-page-content-container-sponsored-component
        v-for="sponsor in sponsors" :key="sponsor.name"
        :image="sponsor.image"
        :link="sponsor.link"
        :title="sponsor.title"
        :domain="sponsor.domain"
      />
    </div>
  </div>
</template>

<script>
import InteriorPageContentContainerSponsoredComponent from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainerSponsoredComponent.vue';

export default {
  name: 'InteriorPageContentContainerSponsored',
  components: {
    InteriorPageContentContainerSponsoredComponent,
  },
  data() {
    return {
      sponsors: [
        // {
        //   image: '/img/sponsored/PIC1.jpg',
        //   link: 'https://www.kqzyfj.com/click-100247257-14405512',
        //   title: 'Glasses',
        //   domain: 'clearly',
        // },
        // {
        //   image: '/img/sponsored/PIC2.jpg',
        //   link: 'https://www.dpbolvw.net/click-100247257-14478406',
        //   title: 'Green Supplies',
        //   domain: 'indigo',
        // },
        // {
        //   image: '/img/sponsored/PIC3.jpg',
        //   link: 'https://www.dpbolvw.net/click-100247257-14478406',
        //   title: 'Green Supplies',
        //   domain: 'indigo.ca',
        // },
        // {
        //   image: '/img/sponsored/PIC4.jpg',
        //   link: 'https://www.anrdoezrs.net/click-100247257-11446157',
        //   title: 'Hardware Supplies',
        //   domain: 'lowes',
        // },
        // {
        //   image: '/img/sponsored/PIC5.jpg',
        //   link: 'https://www.anrdoezrs.net/click-100247257-14102939',
        //   title: 'Moving',
        //   domain: 'taskrabbit',
        // },
        // {
        //   image: '/img/sponsored/PIC6.jpg',
        //   link: 'https://shareasale.com/r.cfm?b=1494893&u=2583593&m=18430&urllink=&afftrack=',
        //   title: 'Senior Products',
        //   domain: 'northshorecare',
        // },
        // {
        //   image: '/img/sponsored/PIC7.jpg',
        //   link: 'https://shareasale.com/r.cfm?b=1669979&u=2583593&m=103622&urllink=&afftrack=0',
        //   title: 'Fall protection',
        //   domain: 'homeexcept',
        // },
        // {
        //   image: '/img/sponsored/PIC8.jpg',
        //   link: 'https://amzn.to/3sA2BTw',
        //   title: 'Blood presure',
        //   domain: 'amazon',
        // },
        // {
        //   image: '/img/sponsored/PIC9.jpg',
        //   link: 'https://amzn.to/3u3LBVV',
        //   title: 'Bed Rail',
        //   domain: 'amazon',
        // },
        // {
        //   image: '/img/sponsored/PIC10.jpg',
        //   link: 'https://www.amazon.ca/gp/product/B08NXWRQW5/ref=as_li_tl?ie=UTF8&tag=keptinview09-20&camp=15121&creative=330641&linkCode=as2&creativeASIN=B08NXWRQW5&linkId=69110493f478e1f29eba4db29e61e393',
        //   title: 'Seniors tablet',
        //   domain: 'amazon',
        // },
        {
          image: '/img/sponsored/PIC12.jpg',
          link: ' https://keptinview.com/kiv-shop/',
          title: 'KIV Shop',
          domain: 'keptinview',
        },
        {
          image: '/img/sponsored/PIC13.jpg',
          link: ' https://keptinview.com/kiv-shop/',
          title: 'KIV Shop',
          domain: 'keptinview',
        },
        {
          image: '/img/sponsored/PIC14.jpg',
          link: ' https://keptinview.com/kiv-shop/',
          title: 'KIV Shop',
          domain: 'keptinview',
        },
        {
          image: '/img/sponsored/PIC15.jpg',
          link: ' https://keptinview.com/kiv-shop/',
          title: 'KIV Shop',
          domain: 'keptinview',
        },
      ],
    };
  },
};
</script>
